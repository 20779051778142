.background(@imageName, @extension: "png", @useDefaultConfiguration: true) {
    background-image: url("/assets/images/@{imageName}.@{extension}");

    /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("/assets/images/@{imageName}@2x.@{extension}");
    }

    & when (@useDefaultConfiguration=true) {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}
