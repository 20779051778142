button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;

    &:focus {
        outline: none;
    }
}

.btn-solid() {
    padding: 12px;
    color: #fff;
    border-radius: 6px;
    font-weight: bold;
    letter-spacing: 0.27px;

    &:disabled {
        opacity: 75%;
        cursor: not-allowed;
    }
}

.btn-solid-blue {
    .btn-solid();

    background-color: #3f5763;

    &[disabled] {
        background-color: gray;
    }
}

.btn-solid-grey-blue {
    .btn-solid();

    background-color: #8baab9;
}

.btn-solid-bright-blue {
    .btn-solid();

    background-color: #6abce6;
}

.btn-solid-yellow {
    .btn-solid();

    background-color: #ffd300;
}

.btn-solid-pink {
    .btn-solid();

    background-color: #f4336a;
}

.btn-solid-green {
    .btn-solid();

    background-color: #44d7b6;
}

.btn-solid-hibiscus {
    .btn-solid();

    background-color: #b9439b;
}

.btn-bordered {
    padding: 10px 12px;
    border-radius: 6px;
    border: solid 1px #3f5763;
    color: #3f5763;
}

.btn-options {
    .background("arrow-down");

    padding: 11px 31px 11px 11px;
    border-radius: 6px;
    border: solid 1px #3f5763;
    color: #3f5763;
    line-height: 1.2;
    text-align: left;
    background-size: 13px 8px;
    background-position: center right 12px;

    &:active {
        background-color: #e3ecf1;
    }
}

.btn-link {
    color: #3f5763;
    letter-spacing: 0.27px;
    text-decoration: underline;
}
